































































































































































































































































































import Vue from "vue";
import { mapState } from "vuex";
import { download } from "@/utils";

export default Vue.extend({
  data() {
    return {};
  },
  computed: {
    ...mapState("app", {
      language: "language",
      isCN: "isCN",
      isMobile: "isMobile"
    }),
    ...mapState("assets", {
      url: "url",
      oss: "oss"
    }),
    communityImages(): string[] {
      return [
        this.oss.am_65_less_r2_swiper_01,
        this.oss.am_65_less_r2_swiper_02,
        this.oss.am_65_less_r2_swiper_03,
        this.oss.am_65_less_r2_swiper_04,
        this.oss.am_65_less_r2_swiper_05,
        this.oss.am_65_less_r2_swiper_06,
        this.oss.am_65_less_r2_swiper_07,
        this.oss.am_65_less_r2_swiper_08,
        this.oss.am_65_less_r2_swiper_09,
        this.oss.am_65_less_r2_swiper_10,
        this.oss.am_65_less_r2_swiper_11,
        this.oss.am_65_less_r2_swiper_12,
        this.oss.am_65_less_r2_swiper_13,
        this.oss.am_65_less_r2_swiper_14,
        this.oss.am_65_less_r2_swiper_15,
        this.oss.am_65_less_r2_swiper_16,
        this.oss.am_65_less_r2_swiper_17,
        this.oss.am_65_less_r2_swiper_18,
        this.oss.am_65_less_r2_swiper_19,
        this.oss.am_65_less_r2_swiper_20,
        this.oss.am_65_less_r2_swiper_21,
        this.oss.am_65_less_r2_swiper_22,
        this.oss.am_65_less_r2_swiper_23,
        this.oss.am_65_less_r2_swiper_24,
        this.oss.am_65_less_r2_swiper_25,
        this.oss.am_65_less_r2_swiper_26,
        this.oss.am_65_less_r2_swiper_27,
        this.oss.am_65_less_r2_swiper_29,
        this.oss.am_65_less_r2_swiper_28,
        this.oss.am_65_less_r2_swiper_30,
        this.oss.am_65_less_r2_swiper_31,
        this.oss.am_65_less_r2_swiper_32,
        this.oss.am_65_less_r2_swiper_33,
        this.oss.am_65_less_r2_swiper_34
      ];
    },
    communityNames(): string[] {
      return this.isCN
        ? [
            "by 熊闹闹",
            "by 熊闹闹",
            "by 熊闹闹",
            "by 熊闹闹",
            "by 熊闹闹",
            "by Sasa",
            "by Sasa",
            "by Sasa",
            "by Sasa",
            "by Sasa",
            "by 小汪同学",
            "by 小汪同学",
            "by 小汪同学",
            "by 小汪同学",
            "by 小汪同学",
            "by 长安五分甜",
            "by 长安五分甜",
            "by 长安五分甜",
            "by 长安五分甜",
            "by 夹克",
            "by 夹克",
            "by 夹克",
            "by 夹克",
            "by 夹克",
            "by 大脸很严格",
            "by 大脸很严格",
            "by 大脸很严格",
            "by 大脸很严格",
            "by hyst",
            "by hyst",
            "by hyst",
            "by hyst",
            "by hyst",
            "by hyst"
          ]
        : [
            "by Xiong Nao Nao",
            "by Xiong Nao Nao",
            "by Xiong Nao Nao",
            "by Xiong Nao Nao",
            "by Xiong Nao Nao",
            "by Sasa",
            "by Sasa",
            "by Sasa",
            "by Sasa",
            "by Sasa",
            "by Xiao Wang Tongxue",
            "by Xiao Wang Tongxue",
            "by Xiao Wang Tongxue",
            "by Xiao Wang Tongxue",
            "by Xiao Wang Tongxue",
            "by Chang'an Wu Fen Tian",
            "by Chang'an Wu Fen Tian",
            "by Chang'an Wu Fen Tian",
            "by Chang'an Wu Fen Tian",
            "by Jiake ",
            "by Jiake ",
            "by Jiake ",
            "by Jiake ",
            "by Jiake ",
            "by Da Lian Hen Yange",
            "by Da Lian Hen Yange",
            "by Da Lian Hen Yange",
            "by Da Lian Hen Yange",
            "by hyst",
            "by hyst",
            "by hyst",
            "by hyst",
            "by hyst",
            "by hyst"
          ];
    }
  },
  methods: {
    onDownload() {
      download(this.oss.pdf_am_65_less_r2);
    },
    onVerify() {
      window.open(this.$paths.verify + "am-65-less/", "_blank");
    }
  }
});
